<template>
  <section class="search">
    <div class="form-group">
      <input class="form-control" placeholder="Введите ФИО нотариуса" v-model="fields.name" @keyup="handleFindSubmit" />
      <div v-if="request !== null" class="notary-list">
        <ul v-if="isLoader || isEmpty">
          <li v-if="isLoader" class="loader"><loader/></li>
          <li v-if="isEmpty" class="empty">Не найдено</li>
        </ul>
        <ul v-if="items.length">
          <li v-for="item in items" :key="item.id">
            <a href="#" @click="(e) => handleSelectNotary(e, item)">
              {{ item.name }} ({{ item.region }})
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="request !== null" class="search-overlay" @click="handleHideSearch"></div>
  </section>
</template>

<script>
import { ref, inject } from 'vue';
import { findAllByNameRequest } from "../../../../api/notary";
import Loader from "../../../../components/common/Loader";
export default {
    name: "SearchComponent",
    components: {
        Loader
    },
    setup() {

        const items = ref([]);
        const request = ref(null);
        const isLoader = ref(false);
        const isEmpty = ref(false);
        const fields = inject('fields');
        const clearFields = inject('clearFields');

        const handleFindSubmit = () => {
            request.value = true;
            isLoader.value = true;
            isEmpty.value = false;
            items.value = [];
            clearFields();
            findAllByNameRequest({
                name: fields.value.name
            }).then(response => {
                request.value = false;
                isLoader.value = false;
                const data = response.data;
                if(data.length) {
                    isEmpty.value = false;
                    items.value = data;
                } else {
                    isEmpty.value = true;
                    items.value = [];
                    fields.value.id = null;
                }
            }).catch(err => console.log(err));
        };

        const handleSelectNotary = (e, notary) => {
            e.preventDefault();
            fields.value.id = parseInt(notary.id);
            fields.value.name = notary.name;
            fields.value.region = notary.region;
            hide();
        };

        const hide = () => {
            request.value = null;
            isLoader.value = false;
            isEmpty.value = false;
            items.value = [];
        };

        const handleHideSearch = () => {
            hide();
        };

        return {
            items,
            fields,
            isLoader,
            isEmpty,
            request,
            handleFindSubmit,
            handleHideSearch,
            handleSelectNotary
        }
    }
}
</script>

<style scoped>

  .search .form-group {
    position: relative;
    z-index: 2;
  }

  .search .notary-list {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    border: 1px solid #CCCCCC;
    border-top: none;
    overflow-y: auto;
    max-height: 141px;
    background-color: #ffffff;
    z-index: 2;
  }

  .search .notary-list ul a {
    display: block;
    background-color: #ffffff;
    padding: 10px;
    border-top: 1px solid #CCCCCC;
    text-decoration: none;
    color: #363637;
  }

  .search .notary-list ul li:hover a {
    background-color: #ededed;
  }

  .search .notary-list>ul li.empty {
    text-align: center;
    padding: 10px;
    border-top: 1px solid #CCCCCC;
    color: #7a7a7a;
  }

  .search .notary-list>ul li.loader {
    text-align: center;
    height: 60px;
    border-top: 1px solid #CCCCCC;
  }

  .search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

</style>
