import { postRequest, getRequest } from './axios';
import { AUTH_URL } from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export function signUpNotaryRequest(id, formData) {
  return postRequest(`${AUTH_URL}/sign-up?id=${id}`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
  });
}

export function verifyEmailTokenRequest(token) {
  return getRequest(`${AUTH_URL}/verify-email-token?token=${token}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

export function createPasswordRequest(token, formData) {
  return postRequest(`${AUTH_URL}/create-password?token=${token}`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

export function loginRequest(formData) {
  return postRequest(`${AUTH_URL}/sign-in`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

export function logoutRequest() {
  return getRequest(`${AUTH_URL}/logout`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function lostPassRequest(formData) {
  return postRequest(`${AUTH_URL}/lost-pass`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

export function verifyPasswordResetTokenRequest(token) {
  return getRequest(`${AUTH_URL}/verify-password-reset-token?token=${token}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

export function resetPasswordRequest(token, formData) {
  return postRequest(`${AUTH_URL}/reset-password?token=${token}`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}
