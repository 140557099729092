<template>
    <section>
      <h2>Адреса электронной почты нотариуса</h2>
      <table class="items">
        <tr>
          <th>№п/п</th>
          <th>Адрес</th>
          <th>Наследники могут отправлять готовые<br>отчеты об оценке на этот адрес</th>
        </tr>
        <item-component v-for="(email,index) in emails" :key="email.id" :index="index" :item="email" :disabled="email.disabled" />
      </table>
      <i v-if="isLastEmailWithSendNotify">Должен быть выбран хотя бы один e-mail адрес для отправки готовых отчетов</i>
      <form @submit="handleAddEmailSubmit">
        <div v-if="isShowForm" class="form-group">
          <input type="text" class="form-control" placeholder="Введите e-mail" v-model="email" />
          <p v-if="error!==''" class="error">{{ error }}</p>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary">Добавить адрес электронной почты</button>
        </div>
      </form>
    </section>
</template>

<script>
import { onMounted, ref, provide } from 'vue';
import { useStore } from 'vuex';
import { createToaster } from '@meforma/vue-toaster';
import { DEFAULT_SHOW_LOADER, DEFAULT_HIDE_LOADER } from "../../../store/constants";
import { getEmailsRequest, addEmailRequest } from "../../../api/user";
import ItemComponent from "./item/ItemComponent";

export default {
    name: "EmailComponent",
    components: {
        ItemComponent
    },
    setup() {

        const emails = ref([]);
        const email = ref('');
        const error = ref('');
        const isShowForm = ref(false);
        const store = useStore();
        const toaster = createToaster({ position: 'top' });
        const isLastEmailWithSendNotify = ref(false);

        const handleAddEmailSubmit = (e) => {
            e.preventDefault();
            if(!isShowForm.value) {
                isShowForm.value = true;
            } else {
                store.commit(DEFAULT_SHOW_LOADER);
                error.value = '';
                addEmailRequest({
                    email: email.value
                }).then(response => {
                    store.commit(DEFAULT_HIDE_LOADER);
                    const data = response.data;
                    if(data) {
                        if(data.errorValidate) {
                            const errorValidate = data.errorValidate;
                            if(errorValidate['form-email']) {
                                error.value = errorValidate['form-email'][0];
                            }
                        } else {
                            isShowForm.value = false;
                            email.value = '';
                            getEmails();
                            toaster.success('E-mail успешно добавлен');
                        }
                    }
                }).catch(err => console.log(err));
            }
        };

        const checkCountWithSendNotify = () => {

            let count = 0;
            emails.value.map(item => {
                if(item.send_notify === 1) {
                    count++;
                }
            });
            if(count===1) {
                emails.value.map(item => {
                    if(item.send_notify === 1) {
                        item.disabled = true;
                    }
                    return item;
                });
                isLastEmailWithSendNotify.value = true;
                return false;
            }

            emails.value.map(item => {
                item.disabled = false;
                return item;
            });
            isLastEmailWithSendNotify.value = false;
            return true;
        };

        const getEmails = () => {
            getEmailsRequest().then(response => {
                const data = response.data;
                if(data) {
                    emails.value = data;
                    emails.value.map(item => {
                        item.disabled = false;
                        return item;
                    });
                    checkCountWithSendNotify();
                }
            }).catch(err => console.log(err));
        };

        onMounted(() => {
            getEmails();
        });

        provide('isLastEmailWithSendNotify', isLastEmailWithSendNotify);
        provide('checkCountWithSendNotify', checkCountWithSendNotify);

        return {
            emails,
            email,
            error,
            isShowForm,
            handleAddEmailSubmit,
            isLastEmailWithSendNotify
        }
    }
}
</script>

<style scoped>

  h2 {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .items {
    width: 50%;
  }

  form {
    margin-top: 10px;
    max-width: 400px;
  }

</style>
