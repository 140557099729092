<template>
    <layout-default>
      <section>
        <form @submit="handleSubmit">
          <h2>Восстановление пароля</h2>
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Укажите e-mail" v-model="email" />
            <p class="error" v-if="error !== ''">{{ error }}</p>
          </div>
          <div class="form-group form-group-actions">
            <button type="submit" class="btn btn-primary">Отправить</button>
            <ul>
              <li><router-link to="/auth/sign-in">Вернуться ко входу</router-link></li>
              <li><router-link to="/auth/sign-up">Регистрация</router-link></li>
            </ul>
          </div>
        </form>
      </section>
    </layout-default>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { lostPassRequest } from "../../api/auth";
import { createToaster } from '@meforma/vue-toaster';
import { DEFAULT_SHOW_LOADER, DEFAULT_HIDE_LOADER } from "../../store/constants";
import LayoutDefault from "../../layouts/LayoutDefault";
export default {
    name: "LostPassView",
    components: {
        LayoutDefault
    },
    setup() {

        const email = ref('');
        const error = ref('');
        const store = useStore();
        const toaster = createToaster({ position: 'top' });

        const handleSubmit = (e) => {
            e.preventDefault();
            error.value = '';
            store.commit(DEFAULT_SHOW_LOADER);
            lostPassRequest({
                email: email.value
            }).then(response => {
                store.commit(DEFAULT_HIDE_LOADER);
                const data = response.data;
                if(data) {
                  if(data.errorValidate) {
                      const errorValidate = data.errorValidate;
                      if(errorValidate['lostpassnotaryform-email']) {
                          error.value = errorValidate['lostpassnotaryform-email'][0];
                      }
                  } else {
                      email.value = '';
                      toaster.success('Письмо с инструкцией было отправлено на указанную почту');
                  }
                }
            }).catch(err => console.log(err));
        };

        return {
            email,
            error,
            handleSubmit
        }
    }
}
</script>

<style scoped>

  section form {
    width: 300px;
    margin: 100px auto 0 auto;
  }

  section .form-group-actions>ul,
  section .form-group-actions>button {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 0;
  }

  section .form-group-actions>ul li {
    margin-bottom: 2px;
  }

</style>
