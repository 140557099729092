import { postRequest, getRequest } from './axios';
import { NOTARY_URL } from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export function findAllByNameRequest(formData) {
  return postRequest(`${NOTARY_URL}/find-all`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
  });
}

export function getEmailsRequest(id) {
  return getRequest(`${NOTARY_URL}/${id}/emails`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}
