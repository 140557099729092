<template>
  <layout-default>
    <section>
      <div class="table">
        <div class="tr">
          <div class="td top">
            <form @submit="handlesLoginSubmit">
              <h2>Вход для нотариусов</h2>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="E-mail нотариальной конторы" v-model="fields.username.value" />
                <p class="error" v-if="fields.username.error !== ''">{{ fields.username.error }}</p>
              </div>
              <div class="form-group">
                <input type="password" class="form-control" placeholder="Пароль" v-model="fields.password.value" />
                <p class="error" v-if="fields.password.error !== ''">{{ fields.password.error }}</p>
              </div>
              <div class="form-group-bottom">
                <div class="form-group">
                  <button type="submit" class="btn btn-primary">Войти</button>
                </div>
                <ul>
                  <li><router-link to="/auth/lost-pass">Забыли пароль?</router-link></li>
                </ul>
              </div>
            </form>
          </div>
        </div>
        <div class="tr">
          <div class="td bottom">
            <ul>
              <li>
                Если вы здесь первый раз, <router-link to="/auth/sign-up">найдите себя в реестре нотариусов</router-link> и выберите пароль для входа.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </layout-default>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useCookies } from 'vue3-cookies';
import { useRouter } from 'vue-router';
import { loginRequest } from "../../api/auth";
import { DEFAULT_SHOW_LOADER, DEFAULT_HIDE_LOADER, AUTH_SET_USER } from "../../store/constants";
import LayoutDefault from "../../layouts/LayoutDefault";
export default {
    name: "SignInView",
    components: {
        LayoutDefault
    },
    setup() {

        const store = useStore();
        const fields = ref({
            username: { value: '', error: '' },
            password: { value: '', error: '' }
        });
        const { cookies } = useCookies();
        const router = useRouter();

        const handlesLoginSubmit = (e) => {
            e.preventDefault();
            fields.value.username.error = '';
            fields.value.password.error = '';
            store.commit(DEFAULT_SHOW_LOADER);
            loginRequest({
                username: fields.value.username.value,
                password: fields.value.password.value,
            }).then(response => {
                store.commit(DEFAULT_HIDE_LOADER);
                const data = response.data;
                if(data) {
                    if(data.errorValidate) {
                        const errorValidate = data.errorValidate;
                        if(errorValidate['signinnotaryform-username']) {
                            fields.value.username.error = errorValidate['signinnotaryform-username'][0];
                        }
                        if(errorValidate['signinnotaryform-password']) {
                            fields.value.password.error = errorValidate['signinnotaryform-password'][0];
                        }
                    } else {
                        cookies.set('token', data.token);
                        store.commit(AUTH_SET_USER, data.user);
                        router.push('/');
                    }
                }
            }).catch(err => console.log(err));
        };

        return {
            fields,
            handlesLoginSubmit
        }
    }
}
</script>

<style scoped>

section,
section .table {
  width: 100%;
  height: 99%;
}

section .top {
  vertical-align: middle;
}

section form {
  width: 400px;
  margin: 0 auto;
  border: 1px solid #44546A;
  padding: 20px 50px;
  border-radius: 20px;
}

section .form-group-bottom>ul,
section .form-group-bottom>.form-group {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 0;
}

section .form-group-bottom>ul li {
  margin-bottom: 2px;
}

section .form-group input,
section .form-group button {
  font-size: 17px;
}

section .bottom {
  text-align: center;
}

section .bottom ul li {
  font-size: 24px;
  font-weight: 500;
}

section .bottom ul li>a {
  color: #00B0F0;
}

</style>
