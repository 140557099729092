import { postRequest, getRequest } from './axios';
import { ORDERS_URL } from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export function getOrdersRequest(formData) {
  return postRequest(`${ORDERS_URL}`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function getFileRequest(orderId, fileId) {
  return getRequest(`${ORDERS_URL}/${orderId}/files/${fileId}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    responseType: 'blob'
  });
}

export function getArchiveRequest(orderId, reportId) {
  return getRequest(`${ORDERS_URL}/${orderId}/reports/${reportId}/zip`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    responseType: 'blob'
  });
}
