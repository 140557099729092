import { postRequest, getRequest, putRequest } from './axios';
import { SETTING_URL } from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export function getSettingDataRequest() {
  return getRequest(`${SETTING_URL}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}

export function getA4PdfRequest() {
  return getRequest(`${SETTING_URL}/a4-pdf`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    responseType: 'blob'
  });
}

export function getA5PdfRequest() {
  return getRequest(`${SETTING_URL}/a5-pdf`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    responseType: 'blob'
  });
}

export function getCartVisitsRequest() {
  return putRequest(`${SETTING_URL}/card-visit`, {},{
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}
