<template>
  <div class="order">
    <span class="number"><b>Заказ: {{ item.number }} <span v-if="item.isNew">NEW</span></b></span>
    <div class="top" @click="handleShowClick">
      <div style="font-size: 24px;">
        <b>Дело №: {{ item.notaryNumber }}</b>
        <span style="padding-right: 20px;"> (наследодатель: {{ item.notaryTestatorName }})</span>
      </div>
      <div style="font-size: 18px;">Отчеты об оценке ({{ item.reports.length }} шт): <span v-if="!display">{{ getReportObject(item) }}</span></div>
      <span class="dropdown-icon">
        <span v-if="!display"><font-awesome-icon icon="chevron-down"/></span>
        <span v-else><font-awesome-icon icon="chevron-up"/></span>
      </span>
    </div>
    <slide-up-down v-model="display" :duration="100">
      <div class="bottom">
        <div class="reports">
          <div v-for="report in item.reports" :key="report.id" class="report">
            <a class="icon" href="#" title="Скачать zip архив" @click="(e) => handleGetArchive(e, report)">
              <font-awesome-icon icon="file-archive"/>
            </a>
            <div>
              <div>
                <a href="#" @click="(e) => handleGetReportFile(e, report)">Отчет об оценке № {{report.number}}</a>
                <template v-if="report.sign">и
                  <a href="#" @click="(e) => handleGetReportSignFile(e, report)">.SIG файл ЭЦП</a>
                </template>
              </div>
              <div>{{ report.object }}</div>
            </div>
            <div class="clear"></div>
          </div>
          <i>(по клику на иконку слева - скачается .zip архив с отчетом и краткой выпиской из него)</i>
        </div>
      </div>
    </slide-up-down>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { DEFAULT_SHOW_LOADER, DEFAULT_HIDE_LOADER } from "../../../../store/constants";
import { getFileRequest, getArchiveRequest } from "../../../../api/orders";

export default {
    name: "ItemComponent",
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    setup(props) {

        const display = ref(false);
        const store = useStore();

        const getReportObject = (order) => {
            return order.reports.map(item => item.object).join(', ');
        };

        const handleShowClick = () => {
            display.value = !display.value;
        };

        const handleGetReportFile = (e, report) => {
            e.preventDefault();
            store.commit(DEFAULT_SHOW_LOADER);
            getFileRequest(props.item.id, report.file.id)
              .then(response => {
                const data = response.data;
                const blob = new Blob([data],{ type: data.type });
                const url = window.URL.createObjectURL(blob, {
                    type: data.type
                });
                const link = window.document.createElement("a");
                link.href = url;
                link.download = report.file.name;
                link.target = '_blank';
                link.click();
                window.URL.revokeObjectURL(url);
                store.commit(DEFAULT_HIDE_LOADER);
            }).catch(err => console.log(err));
        };

        const handleGetReportSignFile = (e, report) => {
            e.preventDefault();
            store.commit(DEFAULT_SHOW_LOADER);
            getFileRequest(props.item.id, report.sign.id)
              .then(response => {
                const data = response.data;
                const blob = new Blob([data],{ type: data.type });
                const url = window.URL.createObjectURL(blob, {
                    type: data.type
                });
                const link = window.document.createElement("a");
                link.href = url;
                link.download = report.sign.name;
                link.target = '_blank';
                link.click();
                window.URL.revokeObjectURL(url);
                store.commit(DEFAULT_HIDE_LOADER);
            }).catch(err => console.log(err));
        };

        const handleGetArchive = (e, report) => {
            e.preventDefault();
            store.commit(DEFAULT_SHOW_LOADER);
            getArchiveRequest(props.item.id, report.id)
              .then(response => {
                const data = response.data;
                const blob = new Blob([data],{ type: data.type });
                const url = window.URL.createObjectURL(blob, {
                    type: data.type
                });
                const link = window.document.createElement("a");
                link.href = url;
                link.download = report.archiveName;
                link.target = '_blank';
                link.click();
                window.URL.revokeObjectURL(url);
                store.commit(DEFAULT_HIDE_LOADER);
            }).catch(err => console.log(err));
        };

        return {
            display,
            getReportObject,
            handleShowClick,
            handleGetReportFile,
            handleGetReportSignFile,
            handleGetArchive
        }
    }
}
</script>

<style scoped>

  .order {
    position: relative;
    border: 1px solid #2E74B5;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 15px;
    color: #2e74b5;
  }

  .order .number {
    position: absolute;
    left: 13px;
    top: -10px;
    background-color: #efefef;
    font-size: 14px;
    color: #2E74B5;
    padding: 1px 10px;
  }

  .order .number>span {
    background-color: #00FFFF;
    display: inline-block;
    padding: 2px;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
  }

  .order .top {
    cursor: pointer;
    position: relative;
  }

  .order .top>div {
    margin-bottom: 8px;
  }

  .order .top>div:last-child {
    margin-bottom: 0;
  }

  .order .bottom {
    padding-top: 10px;
  }

  .order .bottom .reports>i {
    display: block;
  }

  .order .bottom .report {
    margin-bottom: 10px;
  }

  .order .bottom .report:last-child {
    margin-bottom: 0;
  }

  .order .bottom .report a {
    color: #2E74B5;
    margin-right: 5px;
    border-bottom: 1px dashed #2E74B5;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
  }

  .order .bottom .report a.icon {
    border-bottom: none;
    font-size: 44px;
    float: left;
  }

  .order .bottom .report a:hover {
    border-bottom: none;
  }

  .order .bottom .report svg {
    color: #2E74B5;
  }

  .order .bottom .report>div>div:first-child {
    margin-bottom: 6px;
  }

  .order .dropdown-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    color: #2E74B5;
    font-weight: 500;
    font-size: 17px;
    display: block;
    line-height: 37px;
    margin-top: -21px;
  }

  @media (max-width: 992px) {

    .order .bottom .report a {

    }

  }

</style>
