<template>
  <section>
    <loader v-if="loader"/>
    <template v-if="request === false">
      <div v-if="isExist === true">
        <p class="error">Указанный нотариус уже зарегистрирован в системе и имеет логин для входа</p>
      </div>
      <div v-else>
        <template v-if="emails.length">
          <div class="items">
            <h2>У нас есть информация о следующих адресах электронной почты выбранного нотариуса:</h2>
            <div v-for="email in emails" :key="email.id">
              <input type="radio" :value="email.value" v-model="fields.email.value" @click="handleSelectEmail(email)" />
              <span>{{ email.value }}</span>
            </div>
          </div>
          <div class="form-group form-group-custom-email">
            <label>Выберите уже известный адрес электронной почты выше или введите новый:</label>
            <input type="text" class="form-control" v-model="fields.email.value" @change="handleChangeEmail" />
            <p v-if="fields.email.error !== ''" class="error">{{ fields.email.error }}</p>
          </div>
        </template>
        <template v-else>
          <div class="form-group form-group-custom-email">
            <label>Укажите адрес электронной почты нотариальной конторы, который будет использоваться для входа в систему:</label>
            <input type="text" class="form-control" v-model="fields.email.value" />
            <p v-if="fields.email.error !== ''" class="error">{{ fields.email.error }}</p>
          </div>
        </template>
      </div>
    </template>
  </section>
</template>

<script>
import { ref, inject, onMounted } from 'vue';
import { getEmailsRequest } from "../../../../api/notary";
import Loader from "../../../../components/common/Loader";

export default {
    name: "EmailComponent",
    components: {
        Loader
    },
    props: {
        id: {
            type: Number,
            required: true,
            default: null
        }
    },
    setup() {

        const emails = ref([]);
        const loader = ref(false);
        const request = ref(null);
        const isExist = ref(null);
        const fields = inject('fields');

        const getNotaryEmails = () => {
            loader.value = true;
            request.value = true;
            getEmailsRequest(fields.value.id)
                .then(response => {
                    request.value = false;
                    loader.value = false;
                    const data = response.data;
                    if(data) {
                        isExist.value = data.exist;
                        emails.value = data.emails;
                    }
                })
                .catch(err => console.log(err));
        };

        const handleSelectEmail = (email) => {
            fields.value.email.id = email.id;
            fields.value.email.value = email.value;
        };

        const handleChangeEmail = () => {
            fields.value.email.id = null;
        };

        onMounted(() => {
            getNotaryEmails();
        });

        return {
            loader,
            isExist,
            emails,
            request,
            fields,
            handleSelectEmail,
            handleChangeEmail
        }
    }
}
</script>

<style scoped>

  section {
    position: relative;
    min-height: 50px;
  }

  section .items {
    margin-bottom: 20px;
  }

  section .form-group label {
    font-weight: normal;
  }

  section .items>div>input {
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }

  section .items>div>span {
    display: inline-block;
    vertical-align: middle;
  }

  section .items>h2 {
    margin-bottom: 10px;
  }

  section .form-group-custom-email input {
    max-width: 300px;
  }

</style>
